.blog-post-form
{
    width: 80%;
    margin: 80px auto;
}

.blog-post-form button
{
    width: min(350px, 80%);
    margin: auto;
}

.form-group
{
    display: flex;
    flex-direction: column;
    margin: 35px 0px;
}

.form-group textarea
{
    height: 150px;
    resize: vertical;
}