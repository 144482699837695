.project-hero-image
{
    position: relative;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    margin-bottom: 20px;
}

.project-hero-image img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    z-index: -1;

}

.project-hero-text {
    left: 0%;
    bottom: 0%;
    width: 100%;
    padding: 20px 20px;
    text-align: center;
    box-sizing: border-box;
    color: rgb(31, 31, 31);
}

.project-details
{
    width: 80%;
    margin: 0 auto;
}
