/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
  }
  
  .login-form {
    background: #ffffff;
    padding: 40px 60px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .login-title {
    margin-bottom: 30px;
    font-size: 24px;
    color: #333333;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
  }
  
  .login-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .login-button {
    width: 100%;
    padding: 10px 15px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    margin-top: 20px;
    color: #ff0000;
  }

  .form-logo
  {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }