.lightbox
{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.lightbox img
{
    width: auto;
    height: 90vh;
}

.fade-enter {
    opacity: 1;
    transition: opacity 150ms ease-in;
}

.fade-enter.fade-enter-active {
    opacity: 0;
}

.fade-exit {
    opacity: 0;
    transition: opacity 150ms ease-in;
}

.fade-exit.fade-exit-active {
    opacity: 1;
}

.lightbox-loading-text
{
    color: white;
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media screen and (max-width: 768px)
{
    .lightbox
    {
        padding: 0px;
        margin: 0px;
        background-color: rgba(0, 0, 0, 0.7);
        max-width: 100vw;
    }

    .lightbox img
    {
        width: 100%;
        height: auto;
    }
    
}