.project-list-container {
    padding: 20px;
  }
  
  .project-list {
    list-style: none;
    padding: 0;
  }
  
  .project-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .project-list-item div {
    display: flex;
    gap: 10px;
  }
  
  .view-button,
  .delete-button {
    padding: 5px 10px;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .view-button {
    background-color: #007bff;
  }
  
  .view-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  