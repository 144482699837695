header {
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(41, 41, 41);
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    margin: 0px;
    height: 120px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;


}




header .Link
{
    color: rgb(27, 27, 27);
    text-decoration: none;
}



header h1 {
    text-align: center;
}

header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 40px;
}


header nav a {
    color: rgb(22, 22, 22);
    text-decoration: none;
    padding: 1em;
}

header nav a:hover {
    background-color: #555;
    cursor: pointer;
}

header nav li {
    list-style-type: none;
    margin: 10px;
}

header button
{
    background-color: #555;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

header button:hover
{
    background-color: #333;
}


@media screen and (max-width: 768px)
{
    header
    {
        flex-direction: column;
        height: 200px;
        margin: 24px 0px;
    }

    .header-logo-container
    {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    header nav
    {
        flex-direction: column;
        margin: 0px;
        padding: 0px;
    }

    header nav a
    {
        padding: 0px;
        margin: 0px;
    }

    header nav li
    {
        margin: 0px;
        padding: 0px;
    }

    header button
    {
        margin: 10px;
    }
    
}


