.carousel {
  overflow: hidden;
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
  position: relative;
  min-width: 100%; /* Ensure there is always space for at least one image */
}

.carousel-inner img {
  max-width: 80%;
  max-height: 800px;
  object-fit: contain; /* Adjust this to maintain the aspect ratio */
}
  
  .left-arrow, .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.226) !important;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 24px;
    z-index: 0;
    width: 50px;
    height: 250px;
  }
  
  .left-arrow {
    left: 00px;
  }
  
  .right-arrow {
    right: 00px;
  }
  
  @media screen and (max-width: 968px) {
    .left-arrow, .right-arrow {
      width: 40px;
      height: 80px;
      
    }
    
  }