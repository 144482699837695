.contact-form
{
  width: 80%;
  margin: 10px auto 64px auto;
}


.contact-form button
{
  width: min(350px, 80%);
  margin: auto;
}

.contact-form a
{
  color: #333;
  text-decoration: none;

}
  
.form-contact
{
  margin: 35px 0px;
  width: 40%;
}
  
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: calc(100% - 20px);
  margin: 12px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.contact-form textarea {
  height: 150px;
  resize: vertical;
}

@media screen and (max-width: 768px) {
  .form-contact
{
  width: 100%;
}
}