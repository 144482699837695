footer
{
    background-color: #333;
    color: white;
    text-align: center;
    padding: 60px 60px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 1000;
    box-sizing: border-box;
}

footer a
{
    font-size: 10px;
    margin: 0 10px;
    color: white;
    text-decoration: none;
}