.hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.hero-image {
    position: relative;
    height: 75vh;
    width: 96%;
    margin: auto;
    overflow: hidden;

}

.home-services p {
    width: 50%;
    margin: 20px auto;
}


.home-featured-projects {
    width: 100%;
    margin: 80px auto;
}

hr {
    border: 1px solid #583b1f;
    width: 80%;
    margin: 100px auto;
}

h2 {
    text-align: center;
    margin: 20px;
}

.home-featured-projects-container {

    width: 96%;
    margin: auto;
}

.featured-project {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s;
}

.featured-project:hover {
    transform: scale(0.95);
    background-color: rgb(184, 190, 170);

}

.featured-project img {

    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
}

.featured-project h2 {
    width: 300px;
    text-align: center;
    margin: 10px auto;
}

.featured-project p {
    width: 300px;
    text-align: center;
    margin: 10px;
}

.project-column {
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    width: 33%;
}


@media screen and (max-width: 768px) {
    .project-column {
        width: 100%;
    }

    .home-services p {
        width: 80%;
        margin: 20px auto;
    }

}