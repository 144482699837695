.blog-container {
    margin: 50px auto; 
    width: 70%;
    
}

.post-content {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    font-size: 12px;
    
}

.post-content img {
    height: 200px;
    width: 200px;
    margin-right: 20px;
    margin-top: 18px;
    border-radius: 50%;
    object-fit: cover;
    transition: all 0.3s ease;
}

.post-content img:hover {
    transform: scale(1.1);
    
}

.blog-post {
    margin: 20px 0px;
    padding: 24px;
}

.blog-post h3 {
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
}

.post-footer {
    font-size: 10px;
}

.blog-article {
    margin: 20px 0px 50px 0px;
    padding: 24px;
    font-size: 14px;
    width: 80%;
    margin: 0 auto;
}

.blog-article img {
    width: 80%;
    margin: 50px auto;
    display: block;
    
    object-fit: cover;
}

.top-back-button button {
    background: none;
    color: rgb(31, 31, 31);
    width: 100px;
}



.blog-article button
{
    margin: 50px auto;
}

.post-text a {
    text-decoration: none; /* Removes underline */
    color: #d4d4d4; /* Sets the color */
    font-size: 16px;
    font-weight: bold;
    background-color: #181818;
    padding: 10px 20px;
    transition: all 0.3s ease;
}

.post-text a:hover {
    background-color: tomato;
}

.post-text
{
    margin: 5px 24px;
}

@media screen and (max-width: 768px) {

    
    .post-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .post-content img {
        height: 60vw;
        width: 60vw;
        margin-right: 0px;
        margin-top: 0px;
    }

    .blog-container {
        width: 100%;
        margin: 0px;
        text-align: center;
    }

    .blog-article {
        width: 90%;
        margin: 0px auto;
        padding: 0px;
    }

    .blog-article h1
    {
        margin: 48px 0px 0px 0px;
        font-size: 24px;
    }

    .blog-article img {
        width: 100%;
        margin: 50px auto;
    }
    
    
}