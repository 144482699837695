@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Raleway:ital,wght@0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Raleway:ital,wght@0,300;1,300&family=Wix+Madefor+Display:wght@400..800&display=swap');
body {
  font-family: "Wix Madefor Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0px;

  background-color: #e5e0da;
  color: #583b1f;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-size: 3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
