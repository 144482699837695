/* CreateProject.css */
.create-project-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
  }
  
  .create-project-form {
    background: #ffffff;
    padding: 40px 60px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    text-align: center;
  }
  
  .create-project-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333333;
  }
  
  .input-group input,
  .input-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
  }
  
  .input-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  .input-group input[type="file"] {
    padding: 3px;
  }
  
  button {
    width: 100%;
    padding: 10px 15px;
    background-color: #1f1f1f;
    color: #ffffff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: tomato;
  }
  